.case-viewer {
  &-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }

  &-panel {
    width: 100%;
    background-color: white;
    padding: 1em;
    display: flex;
    flex-direction: column;
    gap: 1em;

    border: 0.5px solid lightgray;
    border-radius: 0.25em;
    box-shadow: 1px 1px 5px -3px rgba(0, 0, 0, 0.6);

    &.gray {
      background-color: #f7f9fc;
    }

    &-title {
      width: 100%;
      font-size: 1.2em;
      font-weight: 700;
    }

    &-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 0.25em;
    }
  }

  &-table-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    background: #f7f9fc;
    border: 0.5px solid lightgray;
    border-radius: 0.25em;
    box-shadow: 1px 1px 4px -3px rgba(0, 0, 0, 0.6);

    &.header {
      position: sticky;
      top: 0px;
      z-index: 99;
      background: #c9dcf7;
      font-weight: 900;
    }
  }
}

.gap-half {
  gap: 0.5em;
}
