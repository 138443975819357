#login-screen {
  background-color: $blue;
  .card {
    background-color: $metallic-superlightgray;
    .title {
      color:$blue;
      font-size:18px;
      text-align: center;
      width: 100%;
      font-weight: 100;
      padding-bottom: 1.5rem;
    }
  }
}