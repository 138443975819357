@import "./mixins.scss";

.lex-modal {
  position: absolute;
  inset: 0;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1131;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  padding-left: 60px;

  &-content {
    position: relative;
    margin-top: 8rem;
    max-width: 1600px;
    width: 100%;
    max-height: calc(100% - 10rem);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: white;
    padding: 2rem;

    &-title {
      display: flex;
      flex-direction: row;
      padding-bottom: 1rem;
      border-bottom: 1px solid black;
      font-size: 1.5rem;
      font-weight: 100;
    }
    &-text {
      margin-top: 2rem;
      height: 100%;
      overflow-y: auto;
      @include scrollbar(transparent, rgb(160, 160, 160));
    }
  }

  .lex-banner-close-icon {
    &:hover,
    &:focus {
      color: darkred;
    }
  }
}

.lex-mobile-app-instructions {
  &-image {
    width: 100%;
  }
}

.lex-mobile-add-banner {
  position: absolute;
  top: 66px;
  right: 6px;
  width: 30rem;
  height: 3.5rem !important;
  z-index: 1049;

  .lex-banner-header-text {
    &:hover,
    &:focus {
      color: aquamarine;
    }
  }
}

.lex-mobile-add {
  h1 {
    font-size: 1.2rem;
    font-weight: 400;
    padding-top: 2rem;
  }
  p {
    font-weight: 200;
  }
}

.lex-collapsible-panel {
  &-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    width: max-content;
  }
  &-icon {
    font-size: 0.9rem;
    align-self: center;
  }
  &-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
}

.lex-column {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: max-content;
  max-width: 16%;

  p {
    margin-bottom: 0;
    text-align: center;
    &:first-of-type {
      margin-top: 0.5em;
    }
  }
  &-id {
    position: absolute;
    left: 0.5em;
    top: 0.5em;
    width: 2em;
    height: 2em;
    border-radius: 50%;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3);

    background: rgb(220, 220, 220);
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;

    &.id2 {
      //color: rgb(220, 220, 220);
      //background: rgb(20, 20, 20);
    }
  }
}
