@import "./mixins.scss";

@media only screen and (max-height: 950px) {
  .batman-advanced-search {
    font-size: 0.8rem !important;
  }
  .form-control {
    font-size: 1em;
  }
}

@media only screen and (max-height: 790px) {
  .batman-advanced-search {
    font-size: 0.75rem !important;
  }
  .form-control {
    font-size: 1em;
  }
}

@media only screen and (max-height: 755px) {
  .batman-advanced-search {
    font-size: 0.75rem !important;
  }
  .form-control {
    font-size: 1em;
  }
}

.batman-advanced-search {
  background-color: rgb(248, 249, 250);
  padding: 0.5em;
  padding-top: 0;
  z-index: 5;
  // width: 150%;
  //height: calc(100% - 85px);
  max-height: 93vh !important; // TODO - fix this differently
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  &-title {
    font-size: 1.5em;
    padding: 1em 0 1em 1em;
  }
}

.batman-advanced-search-type-toggle {
  background-color: rgb(248, 249, 250);
  display: flex;
  flex-direction: row;
  gap: 1em;
  padding: 0.5em;
  align-items: center;
  position: sticky;
  top: 0;
  border-bottom: 1px solid darkgray;
  z-index: 1300;

  &-button {
    margin: 0 0.75em;
    cursor: pointer;

    &:hover,
    &:focus {
      color: #43a9fc;
    }

    &.active {
      color: #008cff;
      font-weight: 900;
    }
  }
}

.batman-advanced-search-actions {
  display: flex;
  flex-direction: row;
  gap: 1em;
  justify-content: center;
  padding: 0.5em;
  padding-bottom: 0.5em;

  .btn,
  .fake-btn {
    width: 8em;
  }
}

.batman-advanced-search-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  &.batman-wrapper {
    height: 100%;
    max-width: 100%;
    overflow-y: auto !important;
    @include scrollbar(transparent, rgb(160, 160, 160));
  }
}

.batman-advanced-search-content-half {
  width: calc(50% - 0.5em);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1em;

  & label {
    margin-bottom: 0 !important;
  }

  & input {
    width: 50%;
  }
}

.batman-advanced-search-content-panel {
  width: 50%;
  height: 20em;

  &.large {
    width: 100%;
    height: unset;
  }

  &-title {
    padding-bottom: 0.25em;
    margin-bottom: 0.25em;
    border-bottom: 1px lightgray solid;
  }

  &-content {
    height: 100%;

    .batman-dropdown-list-panel {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
  }
}

.batman-dropdown-list-options {
  padding: 0.25em;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 12em;
  min-height: 12em;
  gap: 0.125em;
  flex-basis: 100%;

  padding-top: 0.5em;
  margin-top: 0.25em;
  border-top: 1px lightgray solid;
  overflow-y: scroll;
  @include scrollbar(transparent, rgb(160, 160, 160));
}

.batman-gap-half {
  gap: 0.5em;
}

.batman-gap-one {
  gap: 1em;
}

.batman-label {
}

.pointer {
  cursor: pointer !important;
}

.disabled {
  opacity: 0.6;
  cursor: not-allowed !important;
}
