.account-screen {
  background-color: $metallic-lightlightgray;
  .form-error {
    font-size: 12px;
    font-style: italic;
    color: $red;
  }
  .save-success {
    font-size: 12px;
    background-color: $green;
    font-weight: 100;
    padding:0.25rem;
    color:$white;
    text-align: center;
  }
  .card {
    background-color: $white;
    &.black .card-header {
      background-color: $black;
    }
    &.blue .card-header {
      background-color: $blue;
    }
    .card-header {
      color:$white;
      font-weight: 100;
    }

    input {
      background-color: $metallic-superlightgray;
      // font-weight: 100;
    }
  }

  
  .saveSuccessAnimated-enter {
    opacity: 0.01;
    transition: opacity 200ms ease-in;
  }
  
  .saveSuccessAnimated-enter.saveSuccessAnimated-enter-active {
    opacity: 1;
    transition: opacity 200ms ease-in;
  }
  
  .saveSuccessAnimated-leave {
    opacity: 1;
    transition: opacity 1000ms ease-in;
  }
  
  .saveSuccessAnimated-leave.saveSuccessAnimated-leave-active {
    opacity: 0.01;
    transition: opacity 1000ms ease-in;
  }

}